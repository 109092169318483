import { CountryCode, Gender, Maybe, Scalars, File, Translation } from 'types/types';

export type AccommodationProviderType = {
  id: Scalars['Int'];
  type: AccommodationProviderTypeType;
  name: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;

  contactPerson: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  comment?: Maybe<Scalars['String']>;

  country?: Maybe<CountryCode>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;

  locations: Array<AccommodationProviderLocation>;
};

export enum AccommodationProviderTypeType {
  ONSITE = 'ONSITE',
  OFFSITE = 'OFFSITE',
  HOST_FAMILY = 'HOST_FAMILY',
}

export type AccommodationProviderSearchCriteria = {
  type?: Maybe<AccommodationProviderTypeType>;
};

export type AccommodationProviderLocation = {
  id: Scalars['Int'];
  providerId: Scalars['Int'];
  providerType: AccommodationProviderTypeType;
  disabled?: Maybe<Scalars['Boolean']>;

  country?: Maybe<CountryCode>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;

  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;

  distanceInMin?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  mapLink?: Maybe<Scalars['String']>;

  accTypeIdWithFood?: Maybe<Scalars['Int']>;
  accTypeIdWithoutFood?: Maybe<Scalars['Int']>;

  flats: Array<AccommodationProviderFlat>;
};

export type AccommodationProviderFlat = {
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  providerType: AccommodationProviderTypeType;
  disabled?: Maybe<Scalars['Boolean']>;

  floor: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  descriptions?: Array<Translation>;
  roomsNumber?: Maybe<Scalars['Int']>;
  bathroomsNumber?: Maybe<Scalars['Int']>;
  ac?: Maybe<Scalars['Boolean']>;

  photos?: Maybe<Array<File>>;

  orderNumber?: Maybe<Scalars['Int']>;

  rooms: Array<AccommodationProviderRoom>;
};

export type AccommodationProviderRoom = {
  id: Scalars['Int'];
  flatId: Scalars['Int'];
  providerType: AccommodationProviderTypeType;
  disabled?: Maybe<Scalars['Boolean']>;

  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptions?: Array<Translation>;
  sizeInSqm?: Maybe<Scalars['Float']>;
  maxStudents?: Maybe<Scalars['Int']>;
  roomTypeId: Scalars['Int'];

  roomTypeIdSingle?: Maybe<Scalars['Int']>;
  roomTypeIdShared?: Maybe<Scalars['Int']>;

  bedsNumber?: Maybe<Scalars['Int']>;
  bedType?: Maybe<AccommodationProviderRoomBedType>;

  pricePerWeekSingle?: Maybe<Scalars['String']>;
  pricePerWeekShared?: Maybe<Scalars['String']>;
  providerPricePerWeek?: Maybe<Scalars['String']>;

  usageType?: Maybe<AccommodationProviderRoomUsageType>;
  gender?: Maybe<Gender>;

  ac?: Maybe<Scalars['Boolean']>;
  bathroom?: Maybe<Scalars['Boolean']>;
  balcony?: Maybe<Scalars['Boolean']>;
  interior?: Maybe<Scalars['Boolean']>;
  exterior?: Maybe<Scalars['Boolean']>;
  hairdryer?: Maybe<Scalars['Boolean']>;
  bedsheets?: Maybe<Scalars['Boolean']>;
  wifi?: Maybe<Scalars['Boolean']>;

  photos?: Maybe<Array<File>>;

  orderNumber?: Maybe<Scalars['Int']>;
};

export enum AccommodationProviderRoomBedType {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
}

export enum AccommodationProviderRoomUsageType {
  ONLY_SINGLE = 'ONLY_SINGLE',
  ONLY_SHARED = 'ONLY_SHARED',
}
