import { ALL_ROLES, LanguageCode, SecurityRole, User } from 'types/types.d';
import { useEffect, useState } from 'react';
import { useTrackedSelector } from 'store/store';

export const passwordMinLength = 6;
export const passwordMaxLength = 100;

/**
 * @return user's language or the default one
 */
export const useUserLanguage = (): LanguageCode => {
  const {
    auth: { user },
  } = useTrackedSelector();

  const [language, setLanguage] = useState(user?.settings?.languageIso6391 || LanguageCode.EN);

  useEffect(() => setLanguage(user?.settings?.languageIso6391 || LanguageCode.EN), [user]);
  return language;
};

/**
 * NOTE: assuming that user has only one role
 *
 * @return true if user has the specified role, false otherwise
 */
export const userHasAnyRole = (user: User | null | undefined, roles: SecurityRole[]): boolean =>
  user != undefined && user && roles?.find((obj) => obj.toString() === user.role) != undefined;

export const userHasAdminRole = (user?: User | null): boolean => userHasAnyRole(user, [SecurityRole.OWNER]);
export const userHasAcademicRole = (user?: User | null): boolean => userHasAnyRole(user, [SecurityRole.ACADEMIC]);
export const userHasItRole = (user?: User | null): boolean => userHasAnyRole(user, [SecurityRole.IT]);
export const userHasTeacherRole = (user?: User | null): boolean => userHasAnyRole(user, [SecurityRole.TEACHER]);

export const userHasSession = (user?: User | null): boolean => userHasAnyRole(user, ALL_ROLES);
